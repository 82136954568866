/**
 SBIR/STTR DATA RIGHTS

 Funding Agreement No. 47QFLA23F0215
 Award Date: 30 Sept 2023
 SBIR/STTR Protection Period: Date of First Deliverable through 20 years
 SBIR/STTR Awardee: Mile Two LLC
 SBIR/STTR Awardee Address: 601 East 3rd Street Dayton, OH 45402

 This is SBIR/STTR Data (or is Computer Software or a Prototype that embodies or includes SBIR/STTR Data)
 to which the SBIR/STTR Awardee has BIR/STTR Data Rights and to which the Federal Government has
 received SBIR/STTR Technical Data Rights (or SBIR/STTR Computer Software Rights) during the SBIR/STTR 
 Protection Period and rights of use for Government Purposes after the SBIR/STTR Protection Period, as those
 terms are defined in the SBIR/STTR Funding Agreement. Awards issues by the U.S. Department of Energy are
 subject to Unlimited Rights after the SBIR/STTR Protection Period, as that term is defined in the SBIR/STTR
 Funding Agreement. Any reproduction of SBIR/STTR Data or portions of such data marked with this legend must
 also reproduce the markings.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  List,
  ListItemText,
  Typography,
} from '@mui/material';
import Image from 'next/image';
import DELVE from '../public/DELVE.png';

interface LoginMessageProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  setAgreed: (open: boolean) => void;
}

export default function LoginMessage({
  open,
  setOpen,
  setAgreed,
}: LoginMessageProps) {
  return (
    <Dialog data-testid="login-dialog-box" open={open}>
      <Box sx={{ml: '32px', mt: '24px'}}>
        <Image
          data-testid="delve-login-logo"
          alt="DELVE LOGO"
          src={DELVE}
          width={160}
        />
      </Box>
      <DialogContent>
        <Typography data-testid="login-intro-text" variant="body2">
          You are accessing a U.S. Government (USG) Information System (IS) that
          is provided for USG-authorized use only. By using this IS (which
          includes any device attached to this IS), you consent to the following
          conditions:{' '}
        </Typography>
        <List dense sx={{listStyleType: 'disc', ml: '32px'}}>
          <ListItemText
            data-testid="usg-monitors-list-item"
            sx={{display: 'list-item'}}
            primaryTypographyProps={{variant: 'body2'}}
          >
            {' '}
            The USG routinely intercepts and monitors communications on this IS
            for purposes including, but not limited to, penetration testing,
            COMSEC monitoring, network operations and defense, personnel
            misconduct (PM), law enforcement (LE), and counterintelligence (CI)
            investigations.{' '}
          </ListItemText>
          <ListItemText
            data-testid="usg-may-seize-list-item"
            sx={{display: 'list-item'}}
            primaryTypographyProps={{variant: 'body2'}}
          >
            {' '}
            At any time, the USG may inspect and seize data stored on this IS.
          </ListItemText>
          <ListItemText
            data-testid="not-private-list-item"
            sx={{display: 'list-item'}}
            primaryTypographyProps={{variant: 'body2'}}
          >
            {' '}
            Communications using, or data stored on, this IS are not private,
            are subject to routine monitoring, interception, and search, and may
            be disclosed or used for any USG-authorized purpose.{' '}
          </ListItemText>
          <ListItemText
            data-testid="security-measures-list-item"
            sx={{display: 'list-item'}}
            primaryTypographyProps={{variant: 'body2'}}
          >
            {' '}
            This IS includes security measures (e.g., authentication and access
            controls) to protect USG interests--not for your personal benefit or
            privacy.
          </ListItemText>
          <ListItemText
            data-testid="see-user-agreement-list-item"
            sx={{display: 'list-item'}}
            primaryTypographyProps={{variant: 'body2'}}
          >
            {' '}
            Notwithstanding the above, using this IS does not constitute consent
            to PM, LE or CI investigative searching or monitoring of the content
            of privileged communications, or work product, related to personal
            representation or services by attorneys, psychotherapists, or
            clergy, and their assistants. Such communications and work product
            are private and confidential. See User Agreement for details.
          </ListItemText>
        </List>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item>
            <Button
              data-testid="agreement-button"
              variant="contained"
              onClick={() => {
                setOpen(false);
                setAgreed(true);
              }}
              sx={{mr: '24px'}}
            >
              I Agree
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
