/**
 SBIR/STTR DATA RIGHTS

 Funding Agreement No. 47QFLA23F0215
 Award Date: 30 Sept 2023
 SBIR/STTR Protection Period: Date of First Deliverable through 20 years
 SBIR/STTR Awardee: Mile Two LLC
 SBIR/STTR Awardee Address: 601 East 3rd Street Dayton, OH 45402

 This is SBIR/STTR Data (or is Computer Software or a Prototype that embodies or includes SBIR/STTR Data)
 to which the SBIR/STTR Awardee has BIR/STTR Data Rights and to which the Federal Government has
 received SBIR/STTR Technical Data Rights (or SBIR/STTR Computer Software Rights) during the SBIR/STTR 
 Protection Period and rights of use for Government Purposes after the SBIR/STTR Protection Period, as those
 terms are defined in the SBIR/STTR Funding Agreement. Awards issues by the U.S. Department of Energy are
 subject to Unlimited Rights after the SBIR/STTR Protection Period, as that term is defined in the SBIR/STTR
 Funding Agreement. Any reproduction of SBIR/STTR Data or portions of such data marked with this legend must
 also reproduce the markings.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */

'use client';

import React from 'react';
import {Box, CssBaseline, Typography, AppBar} from '@mui/material';
import {useSession} from 'next-auth/react';

function CUI() {
  return (
    <Typography
      data-testid="cui-banner"
      fontSize={16}
      fontWeight="medium"
      color="white"
      align="center"
    >
      {' '}
      CUI{' '}
    </Typography>
  );
}

function Header() {
  return (
    <CssBaseline>
      <AppBar
        sx={{
          backgroundColor: '#502b85',
          height: 24,
          width: 1,
          position: 'fixed',
          opacity: 1,
        }}
        elevation={0}
      >
        <CUI />
      </AppBar>
      <Box
        sx={{
          height: 24,
          width: 1,
        }}
      />
    </CssBaseline>
  );
}

function CUIFooter() {
  return (
    <CssBaseline>
      <Box
        sx={{
          backgroundColor: '#502b85',
          height: 24,
          width: 1,
          position: 'fixed',
          left: 0,
          bottom: 0,
          right: 0,
          opacity: 1,
          elevation: 0,
          mt: 'auto',
        }}
      >
        <CUI />
      </Box>
    </CssBaseline>
  );
}

function Footer() {
  const {data: session} = useSession();
  return (
    <CssBaseline>
      {/* @ts-ignore */}
      {session &&
        // @ts-ignore
        session?.error !== 'RefreshAccessTokenError' &&
        // @ts-ignore
        session?.error !== 'NotAuthorized' && (
          <Box
            data-testid="footer"
            component="footer"
            sx={{
              height: 56,
              width: '100%',
              position: 'relative',
              bottom: '24px',
              elevation: 0,
              mt: '20px',
              backgroundColor: 'grays.dark',
              overflow: 'hidden',
            }}
          />
        )}
    </CssBaseline>
  );
}

export {Header, Footer, CUIFooter};
